import React from "react";
import {useNavigate} from "react-router-dom";
import {BinList} from "../components/inventory/BinList";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";

const BinsPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    
    return (
        (user && user.viewbininventory) ? <BinList navigate={navigate} /> : <AccessDenied />
    );
}

export default BinsPage;
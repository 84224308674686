import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import SingleBin from "../components/inventory/SingleBin";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";

const SingleBinPage = () => {
    const user = secureStorage.getItem("user");
    let {binId} = useParams();
    const navigate = useNavigate();
    
    return (
        (user && user.viewbininventory) ? <SingleBin binId={binId} navigate={navigate} /> : <AccessDenied />
    )
}

export default SingleBinPage;
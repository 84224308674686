const largeButtonStyle = {
    width: '100%',
    minHeight: '3em',
    fontSize: 20,
    backgroundColor: '#6c757d',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
    marginTop: 10,
    marginBottom: 10
}

const thinButtonStyle = {
    width: '100%',
    height: 50,
    fontSize: 18,
    backgroundColor: '#6c757d',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
    marginTop: 10,
    marginBottom: 10
}

const smallButtonStyle = {
    width: '100%',
    minHeight: '2em',
    fontSize: 20,
    backgroundColor: '#6c757d',
    border: 'none',
    color: 'white',
    borderRadius: '5px'
}

const tableButtonStyle = {
    padding: 6,
    backgroundColor: '#6c757d',
    border: 'none',
    color: 'white',
    borderRadius: '5px'
}

const colors = {
    blue: '#3d7eb2',
    green: '#417540',
    error: '#ff0000',
    grey: '#c0c0c0'
}

export {largeButtonStyle, thinButtonStyle, smallButtonStyle, tableButtonStyle, colors};
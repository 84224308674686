import React, {Component} from "react";
import {NavLink} from "reactstrap";
import { Link } from 'react-router-dom';
import secureStorage from "react-secure-storage";
import BackButton from "../utility/BackButton";
import Loading from "../utility/Loading";

export class FulfillmentsList extends Component {
    static displayName = FulfillmentsList.name;

    constructor(props) {
        super(props);
        this.user = secureStorage.getItem("user");
        this.navigate = props.navigate;
        this.state = { fulfillments: [], loading: true};
    }
    
    componentDidMount() {
        this.loadFulfillments();
    }

    render() {
        let loading = this.state.fulfillments.length <= 0 || this.state.loading;
        let fulfillments = this.state.fulfillments;

        return (
            <div>
                <h1><BackButton to={'/'} />  All Fulfillments</h1>
                {loading ? <Loading />
                    :
                    <table className='table table-striped' aria-labelledby="tableLabel">
                        <thead>
                        <tr>
                            <th>Doc. No.</th>
                            <th>Customer</th>
                            <th>Status</th>
                            <th>Ship Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fulfillments.map(fulfillment =>
                            <tr  style={{color: fulfillment.bmsstatus ? 'red' : 'black'}} key={fulfillment.internalid}>
                                <td>
                                    <NavLink tag={Link} to={'/fulfillments/' + fulfillment.internalid} >
                                        <div>{fulfillment.tranid}</div>
                                        <div style={{fontStyle: 'italic'}}>{fulfillment.sonumber}</div>
                                    </NavLink>
                                </td>
                                <td>
                                    <NavLink tag={Link} to={'/fulfillments/' + fulfillment.internalid} >{fulfillment.name}</NavLink>
                                </td>
                                <td>
                                    <NavLink tag={Link} to={'/fulfillments/' + fulfillment.internalid} >
                                        <div>{fulfillment.bmsstatus ? fulfillment.bmsstatus : 'Open'}</div>
                                        <div style={{fontStyle: 'italic'}}>{fulfillment.picker}</div>
                                    </NavLink>
                                </td>
                                <td>
                                    <NavLink tag={Link} to={'/fulfillments/' + fulfillment.internalid} >{fulfillment.shipdate}</NavLink>
                                    <NavLink tag={Link} to={'/fulfillments/' + fulfillment.internalid} >{fulfillment.lineofbusiness}</NavLink>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
            </div>
        );
    }

    async loadFulfillments() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/fulfillments/' + this.user.location + '/all', requestOptions);
        const data = await response.json();
        this.setState({ fulfillments: data, loading: false});
    }
}

import Home from "./pages/Home";
import InventoryPage from "./pages/InventoryPage";
import FulfillmentsPage from "./pages/FulfillmentsPage";
import BinsPage from "./pages/BinsPage";
import Logout from "./components/Logout";
import SingleBinPage from "./pages/SingleBinPage";
import SingleFulfillmentPage from "./pages/SingleFulfillmentPage";
import PickOrdersPage from "./pages/PickOrdersPage";
import SingleItemPage from "./pages/SingleItemPage";
import BinTransferPage from "./pages/BinTransferPage";
import EditBinPage from "./pages/EditBinPage";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/inventory',
    element: <InventoryPage />
  },
  {
    path: '/bins',
    element: <BinsPage />
  },
  {
    path: '/bins/:binId',
    element: <SingleBinPage />
  },
  {
    path: '/bins/:id/edit',
    element: <EditBinPage />
  },
  {
    path: '/binTransfer/:id',
    element: <BinTransferPage />
  },
  {
    path: '/inventory/:itemId',
    element: <SingleItemPage />
  },
  {
    path: '/pickOrders',
    element: <PickOrdersPage />
  },
  {
    path: '/fulfillments',
    element: <FulfillmentsPage />
  },
  {
    path: '/fulfillments/:id',
    element: <SingleFulfillmentPage />
  },
  {
    path: '/logout',
    element: <Logout />
  },
];

export default AppRoutes;

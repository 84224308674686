import React from "react";
import {InventoryList} from "../components/inventory/InventoryList";
import {useNavigate} from "react-router-dom";
import AccessDenied from "../components/utility/AccessDenied";
import secureStorage from "react-secure-storage";

const InventoryPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    return (
        (user && user.viewbininventory) ? <InventoryList navigate={navigate} /> : <AccessDenied />
    )
}

export default InventoryPage;
import {NavLink} from "react-router-dom";
const ItemBinList = ({bins}) => {
    return (
        <>
            <h3>Bin Inventory</h3>
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                <tr>
                    <th>Bin</th>
                    <th style={{textAlign: "right"}}>On Hand</th>
                    <th style={{textAlign: "right"}}>Available</th>
                </tr>
                </thead>
                <tbody>
                {bins.map(bin =>
                    <tr key={bin.bininternalid}>
                        <td>
                            <NavLink to={'/bins/' + bin.bininternalid}>
                                {bin.binnumber}
                            </NavLink>
                            {bin.memo && <div><span style={{fontStyle: 'italic'}}>{bin.memo}</span></div>}
                        </td>
                        <td style={{textAlign: "right"}}>{bin.onhand}</td>
                        <td style={{textAlign: "right"}}>{bin.available}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    );
}

export default ItemBinList;
import React, {Component} from "react";
import {Button, Input} from "reactstrap";
import secureStorage from "react-secure-storage";
import {largeButtonStyle} from "../../styles/BearStyles";

export class BinList extends Component {
    static displayName = BinList.name;

    constructor(props) {
        super(props);
        this.user = secureStorage.getItem("user");
        this.navigate = props.navigate;
        this.state = { loading: false, search: '', errorMessage: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    handleChange(event){
        this.setState({search: event.target.value});
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loading: true});
        this.loadItems(this.state.search);
    }
    
    handleBack(){
        this.navigate('/');
    }

    render() {
        if (this.state.loading){
            return <p><em>Loading...</em></p>;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <h1><Button onClick={this.handleBack}>Back</Button>   Search Bins</h1>
                <Input autoFocus type={"text"} value={this.state.skuSearch} onChange={this.handleChange}/>
                <input type={"submit"}
                       value={"Search"}
                       style={largeButtonStyle}
                />
                <p style={{textAlign: 'center', marginTop: 5}}>{this.state.errorMessage}</p>
            </form>
        )
    }
    async loadItems(search) {
        if (search.length < 1){
            this.setState({items: [], loading: false, errorMessage: 'Search is empty'});
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };

        const response = await fetch('api/items/bins/lookup/' + this.user.location + "/" + search, requestOptions);
        if (response.status !== 200){

            const itemResponse = await fetch('api/items/byUpc/' + search, requestOptions);
            const itemData = await itemResponse.json();
            const error = itemData.length > 0 ? '' : 'No results';
            if(!error){
                this.navigate(`/inventory/${itemData[0].internalid}`);
                return;
            }
            
            this.setState({ loading: false, errorMessage: 'No results' });
            return;
        }
            
        const data = await response.json();
        
        this.navigate(`/bins/${data.internalid}`);
    }
}

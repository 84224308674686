import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Input} from "reactstrap";
import {largeButtonStyle} from "../../styles/BearStyles";
import secureStorage from "react-secure-storage";
import Loading from "./Loading";

const ScannerGlobalSearch = () => {
    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const user = secureStorage.getItem("user");
        if (search.length < 1)
            return;
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };

        const response = await fetch('api/search/' + user.location + "/" + search, requestOptions);
        const data = await response.json();
        if (data.type === 'none'){
            setLoading(false);
            return;
        }

        navigate(`/${data.type}/${data.internalid}`);
    }
    const handleChange = (event) => {
        setSearch(event.target.value);
    }
    
    if (loading){
        return <Loading />;
    }

    return (
        <form onSubmit={handleSubmit} style={{marginBottom: 10}}>
            <Input autoFocus type={"text"} placeholder={'Scan'} value={search} onChange={handleChange} />
            <input hidden type={"submit"} value={"Search"} style={largeButtonStyle}/>
        </form>
    )
}

export default ScannerGlobalSearch;
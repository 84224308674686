import {useNavigate} from "react-router-dom";
import {Button, Container} from "reactstrap";
import icon from '../../images/restriction.png'
import {largeButtonStyle} from "../../styles/BearStyles";
const AccessDenied = () => {
    const navigate = useNavigate();

    return (
            <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img height={100} width={100} src={icon} alt={'Access Denied'} />
                <h2>Access Denied</h2>
                <p>Contact your administrator for more details.</p>
                <Button onClick={() => navigate('/')} style={largeButtonStyle}>Return Home</Button>
            </Container>
    );
}

export default AccessDenied;
import React, {Component} from "react";
import secureStorage from "react-secure-storage";
import ItemHeader from "./ItemHeader";
import Loading from "../utility/Loading";
import {Button, Input} from "reactstrap";
import {colors, largeButtonStyle} from "../../styles/BearStyles";

class BinTransfer extends Component {
    constructor(props) {
        super(props);
        this.navigate = props.navigate;
        this.user = secureStorage.getItem("user");
        this.id = props.id;
        this.state = {
            item: null, 
            bin: null, 
            toBin: null, 
            quantity: 0, 
            bins: [], 
            mode: 'Select FROM Bin', 
            errorMessage: '', 
            binSearch: '',
            loading: false
        }
        this.handleSelectFromBin = this.handleSelectFromBin.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleBinSearchChange = this.handleBinSearchChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmTransfer = this.confirmTransfer.bind(this);
    }
    
    componentDidMount() {
        this.loadItem(this.id);
        this.loadBins(this.id);
    }
    
    handleSelectFromBin = (bin) => this.setState({bin: bin, mode: 'Enter Quantity to Transfer'});
    handleQuantityChange = (event) => this.setState({quantity: event.target.value});
    handleBinSearchChange = (event) => this.setState({binSearch: event.target.value});
    handleFocus = (event) => event.target.select();
    handleSubmit(event){
        event.preventDefault();
        if (event.target.name === 'submitQuantity'){
            const currentQuantity = parseInt(this.state.quantity)
            if (currentQuantity <= 0 || currentQuantity > this.state.bin.available){
                this.setState({errorMessage: 'Quantity must be greater than 0 and less than or equal to max qty.'});
                return;
            }
            this.setState({errorMessage: '', mode: 'Select TO Bin'});
            return;
        }
        
        if (event.target.name === 'submitBinSearch'){
            const search = this.state.binSearch;
            if (search.length < 1){
                this.setState({errorMessage: 'Nothing entered.'});
                return;
            }
            this.lookupBin(search);
        }
    }
    confirmTransfer = () => {
        this.submitTransfer();
    }

    render() {
        const bin = this.state.bin;
        const toBin = this.state.toBin;
        const bins = this.state.bins;
        const mode = this.state.mode;
        return (
            <>
                {this.state.item ? <ItemHeader item={this.state.item} showInventory={false} hideUPC={true} /> : <Loading />}
                <h1 style={{textAlign: "center"}}>Bin Transfer</h1>
                {bin && <h3 style={{textAlign: "center"}}>FROM: {bin.binnumber}</h3>}
                {bin && <h3 style={{textAlign: "center"}}>Transfer Quantity: {this.state.quantity}</h3>}
                {bin && toBin && <h3 style={{textAlign: "center"}}>TO: {toBin.binnumber}</h3>}
                <hr />
                <h2 style={{textAlign: "center"}}>{mode}</h2>
                {mode === 'Select FROM Bin' && bins.map((bin) => 
                    <Button disabled={bin.available <= 0} key={bin.bininternalid} onClick={() => this.handleSelectFromBin(bin)} style={largeButtonStyle}>
                        {bin.binnumber} (Available: {bin.available})
                    </Button>)
                }
                {mode === 'Enter Quantity to Transfer' &&
                    <form onSubmit={this.handleSubmit} name={"submitQuantity"}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Input onFocus={this.handleFocus} 
                                   style={{width: 120, height: 80, fontSize: 30, textAlign: 'center', marginTop: 10, marginBottom: 10}} 
                                   autoFocus 
                                   type={"number"} 
                                   value={this.state.quantity} 
                                   onChange={this.handleQuantityChange}
                            />
                            <h3>Max Qty: {bin.available}</h3>
                            <input type={"submit"} value={"Submit"} style={largeButtonStyle} />
                        </div>
                    </form>
                }
                {mode === 'Select TO Bin' &&
                    <>
                        {this.state.loading ? <Loading /> :
                        <form onSubmit={this.handleSubmit} name={"submitBinSearch"}>
                            <Input autoFocus type={"text"} value={this.state.binSearch} onChange={this.handleBinSearchChange}/>
                            <input type={"submit"} value={"Search"} style={largeButtonStyle}/>
                        </form>
                        }
                    </>
                }
                {mode === 'Confirm Bin Transfer' &&
                    (this.state.loading ? <Loading /> : <Button style={largeButtonStyle} onClick={this.confirmTransfer}>Submit</Button>)
                }
                <p style={{textAlign: 'center', marginTop: 5, color: colors.error, fontWeight: 'bold'}}>{this.state.errorMessage}</p>
            </>
        );
    }

    async loadItem(itemId) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/items/' + this.user.location + '/' + itemId + '/info', requestOptions)
        const data = await response.json();
        this.setState({ item: data });
    }
    
    async loadBins(itemId) {
        if (itemId.length < 2){
            this.setState({bins: [], loading: false});
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/items/' + this.user.location + '/' + itemId + '/bins', requestOptions)
        const data = await response.json();
        this.setState({ bins: data, loading: false});
    }

    async lookupBin(search) {
        this.setState({loading: true})
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };

        const response = await fetch('api/items/bins/lookup/' + this.user.location + "/" + search, requestOptions)
        if (response.status !== 200){
            this.setState({ loading: false, errorMessage: 'No results' });
            return;
        }

        const data = await response.json();
        const toBin = data;
        this.setState({loading: false, toBin: toBin, mode: 'Confirm Bin Transfer', errorMessage: ''});
    }
    
    async submitTransfer(){
        this.setState({loading: true});
        const data = {
            type: 'bintransfer',
            item: this.id,
            bin: this.state.bin.bininternalid,
            toBin: this.state.toBin.internalid,
            quantity: this.state.quantity,
            location: this.user.location,
            memo: 'BearWMS ' + this.user.username
        }
        console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.user.token}` },
            body: JSON.stringify(data)
        };

        const response = await fetch('api/Items/bintransfer', requestOptions);
        if (response.status !== 200){
            this.setState({errorMessage: 'Failed to post transfer.', loading: false});
            return;
        }
        this.navigate('/inventory/' + this.id);
    }
}

export default BinTransfer;
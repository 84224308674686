import React from 'react';
import {Button, Container} from "reactstrap";
import {NavLink} from "react-router-dom";
import {largeButtonStyle} from "../styles/BearStyles";
import secureStorage from "react-secure-storage";
import ScannerGlobalSearch from "../components/utility/ScannerGlobalSearch";

const navStyle = {
    width: '100%'
}
const Home = () => {
    const user = secureStorage.getItem("user");

    return (
      <Container>
          <ScannerGlobalSearch />
          {user && user.viewbininventory &&
              <>
                  <h1 style={{textAlign: 'center', paddingBottom: 20, marginBottom: 10, borderBottom: '1px solid lightgrey'}}>Inventory</h1>
                  <NavLink style={navStyle} to={'/bins'}>
                      <Button style={largeButtonStyle}>Count Bin</Button>
                  </NavLink>
                  <NavLink style={navStyle} to={'/inventory'}>
                      <Button style={largeButtonStyle}>Item Lookup</Button>
                  </NavLink>
              </>
          }
          {user && user.vieworders && 
              <>
                  <h1 style={{textAlign: 'center', paddingBottom: 20, marginBottom: 10, borderBottom: '1px solid lightgrey'}}>Orders</h1>
                  <NavLink style={navStyle} to={'/pickOrders'}>
                      <Button style={largeButtonStyle}>Pick Orders</Button>
                  </NavLink>
              </>
          }
      </Container>
    );
}

export default Home;

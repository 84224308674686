import React, {Component} from "react";
import {Button, Input, NavLink} from "reactstrap";
import { Link } from 'react-router-dom';
import secureStorage from "react-secure-storage";
import {largeButtonStyle} from "../../styles/BearStyles";

export class InventoryList extends Component {
    static displayName = InventoryList.name;

    constructor(props) {
        super(props);
        this.user = secureStorage.getItem("user");
        this.navigate = props.navigate;
        this.state = { items: [], loading: false, skuSearch: '', errorMessage: '' };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleBackHome = this.handleBackHome.bind(this);
    }
    
    handleChange(event){
        this.setState({skuSearch: event.target.value});
    }
    
    handleSubmit(event){
        event.preventDefault();
        this.setState({loading: true});
        this.loadItems(this.state.skuSearch);
    }
    
    handleBack(){
        this.setState({items:[], skuSearch: ''})
    }
    
    handleBackHome(){
        this.navigate('/');
    }

    static renderItems(items) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                <tr>
                    <th>SKU</th>
                    <th>Display Name</th>
                    <th>On Hand</th>
                    <th>Available</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item =>
                    <tr key={item.internalid}>
                        <td>
                            <NavLink tag={Link} to={'/inventory/' + item.internalid} >
                                {item.itemid}
                            </NavLink>
                        </td>
                        <td>
                            <NavLink tag={Link} to={'/inventory/' + item.internalid} >
                                {item.displayname}
                            </NavLink>
                        </td>
                        <td>
                            <NavLink tag={Link} to={'/inventory/' + item.internalid} >
                                {item.quantityonhand}
                            </NavLink>
                        </td>
                        <td>
                            <NavLink tag={Link} to={'/inventory/' + item.internalid} >
                                {item.quantityavailable}
                            </NavLink>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : InventoryList.renderItems(this.state.items);
            
        let hasItems = this.state.items.length > 0;
        
        if (!hasItems){
            if (this.state.loading){
                return <p><em>Loading...</em></p>;
            }
            
            return (
                <form onSubmit={this.handleSubmit}>
                    <h1><Button style={{marginRight: 10}} onClick={this.handleBackHome}>Back</Button>Search Items</h1>
                    <Input autoFocus type={"text"} value={this.state.skuSearch} onChange={this.handleChange}/>
                    <input type={"submit"} 
                           value={"Search"} 
                           style={largeButtonStyle} 
                    />
                    <p style={{textAlign: 'center', marginTop: 5}}>{this.state.errorMessage}</p>
                </form>
            )
        }

        return (
            <div>
                <h1 id="tableLabel" >
                    <Button style={{marginRight: 10}} onClick={this.handleBack}>Back</Button>
                    Items
                </h1>
                {contents}
            </div>
        );
    }

    async loadItems(skuSearch) {
        if (skuSearch.length < 2){
            this.setState({items: [], loading: false, errorMessage: 'Search must be at least 2 characters long'});
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/items/' + this.user.location + '/' + skuSearch, requestOptions);
        const data = await response.json();
        const error = data.length > 0 ? '' : 'No results';
        if (error){
            const binResponse = await fetch('api/items/bins/lookup/' + this.user.location + "/" + skuSearch, requestOptions)
            if (binResponse.status !== 200){
                this.setState({ items: [], loading: false, errorMessage: 'No results' });
                return;
            }
            const binData = await binResponse.json();
            this.navigate(`/bins/${binData.internalid}`);
            return;
        }
        this.setState({ items: data, loading: false, errorMessage: error });
    }
}

import React, {Component} from "react";
import {Input} from "reactstrap";
import secureStorage from "react-secure-storage";
import {largeButtonStyle} from "../styles/BearStyles";
import Loading from "./utility/Loading";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', loading: false, error: ''}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'username')
            this.setState({username: value});
        if (name === 'password')
            this.setState({password: value});
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loading: true});
        this.login(this.state.username, this.state.password);
    }

    render() {
        if (this.state.loading) return <Loading />
        
        return (
            <form onSubmit={this.handleSubmit}>
                <h1 style={{marginBottom: 20}}>Login</h1>
                <Input type={"text"} name={'username'} autoComplete={'on'} value={this.state.username} onChange={this.handleChange}/>
                <p>Username</p>
                <Input type={"password"} name={'password'} autoComplete={'on'} value={this.state.password} onChange={this.handleChange}/>
                <p>Password</p>
                <input type={"submit"}
                       value={"LOGIN"}
                       style={largeButtonStyle}
                />
                <p style={{textAlign: 'center', marginTop: 5}}>{this.state.error}</p>
            </form>
        );
    }
    
    async login(username, password){
        this.setState({loading: true});
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        };
        const response = await fetch('api/Login', requestOptions);
        let error = '';
        if (response.status !== 200){
            error = 'Username/Password not valid.';
            this.setState({loading: false, error: error});
            return;
        }
        
        const user = await response.json();
        secureStorage.setItem("user", user);
        window.location.reload();
    }
}

export default LoginForm;
import LoginPage from "./pages/LoginPage";


const PublicRoutes = [
    {
        path: '/*',
        element: <LoginPage />
    }
]

export default PublicRoutes;
import React, {Component} from "react";
import {Button} from "reactstrap";
import BinCountForm from "./BinCountForm";
import secureStorage from "react-secure-storage";
import {largeButtonStyle} from "../../styles/BearStyles";
import ScannerGlobalSearch from "../utility/ScannerGlobalSearch";
import {NavLink} from "react-router-dom";

class SingleBin extends Component {
    static displayName = SingleBin.name;

    constructor(props) {
        super(props);
        this.user = secureStorage.getItem("user");
        this.binId = props.binId;
        this.navigate = props.navigate;
        this.state = { bin: null, items: [], loading: false, search: '', errorMessage: '', enteringCount: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.changeToEnteringCount = this.changeToEnteringCount.bind(this);
        this.cancelEnteringCount = this.cancelEnteringCount.bind(this);
        this.handleEditBinInfo = this.handleEditBinInfo.bind(this);
    }
    
    componentDidMount() {
        this.setState({loading: true});
        this.loadBinInfo(this.binId);
        this.loadItems(this.binId);
    }

    handleChange(event){
        this.setState({search: event.target.value});
    }

    handleBack(){this.navigate(-1)}
    
    handleEditBinInfo(){this.navigate('/bins/' + this.binId + '/edit')}

    changeToEnteringCount(){
        this.setState({enteringCount: true});
    }

    cancelEnteringCount(){
        this.setState({enteringCount: false});
    }

    static renderItems(items) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                <tr>
                    <th>Item</th>
                    <th>Display Name</th>
                    <th>On Hand</th>
                    <th>Available</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item =>
                    <tr key={item.iteminternalid}>
                        <td><NavLink to={`/inventory/${item.iteminternalid}`}>{item.itemid}</NavLink></td>
                        <td>{item.displayname}</td>
                        <td>{item.onhand}</td>
                        <td>{item.available}</td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : SingleBin.renderItems(this.state.items);

        let enteringCount = (!this.state.loading && this.state.enteringCount);
        let bin = this.state.bin;

        if (!enteringCount){
            return (
                <div>
                    <h1 id="tableLabel" >
                        <Button style={{marginRight: 10}} onClick={this.handleBack}>Back</Button>
                        {bin && bin.binnumber}
                        {bin && this.user.updatebininfo && <Button style={{marginLeft: 30}} onClick={this.handleEditBinInfo}>Edit</Button>}
                    </h1>
                    <p>{bin && bin.memo}</p>
                    <p>Last Count: {bin && bin.lastcount}</p>
                    {contents}
                    <Button onClick={this.changeToEnteringCount} style={largeButtonStyle}>Enter Count</Button>
                </div>
            );
        }

        return <BinCountForm items={this.state.items} bin={this.state.bin} Cancel={this.cancelEnteringCount} />
    }

    async loadBinInfo(binId) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };

        const response = await fetch('api/items/bins/lookupById/' + binId, requestOptions)
        if (response.status !== 200){
            this.setState({ loading: false, errorMessage: 'No results' });
            return;
        }

        const data = await response.json();
        this.setState({bin: data});
    }

    async loadItems(binId) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };

        const response = await fetch('api/items/bins/single/' + binId, requestOptions)
        const data = await response.json();
        const error = data.length > 0 ? '' : 'No results';
        this.setState({ items: data, loading: false, errorMessage: error });
    }
}

export default SingleBin;
import {useEffect} from "react";
import secureStorage from "react-secure-storage";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        secureStorage.removeItem("user");
        navigate('/');
    },[])
    
    return (
        <>
            <h1>Logging out...</h1>
        </>
    )
}

export default Logout;
import React, {Component} from "react";
import secureStorage from "react-secure-storage";
import BackButton from "../utility/BackButton";
import {Button} from "reactstrap";
import {colors, largeButtonStyle, tableButtonStyle} from "../../styles/BearStyles";
import Loading from "../utility/Loading";
import PickFulfillmentLine from "./PickFulfillmentLine";
class SingleFulfillment extends Component {
    constructor(props) {
        super(props);
        this.id = props.id;
        this.user = secureStorage.getItem("user");
        this.navigate = props.navigate;
        this.state = {fulfillment: null, items: [], item: null, inventorydetail: null}
        this.handleBack = this.handleBack.bind(this);
        this.openInventoryDetail = this.openInventoryDetail.bind(this);
        this.closeInventoryDetail = this.closeInventoryDetail.bind(this);
        this.markComplete = this.markComplete.bind(this);
        this.handleUpdateFulfillmentStatus = this.handleUpdateFulfillmentStatus.bind(this);
    }
    
    componentDidMount() {
        this.loadFulfillmentHeader(this.id);
        this.loadItems(this.id);
    }

    handleBack(){this.navigate('/');}
    
    openInventoryDetail(inventoryDetail, item){
        this.setState({inventorydetail: inventoryDetail, item: item});
    }
    
    closeInventoryDetail() {this.setState({inventorydetail: null, item: null});}
    
    async handleUpdateFulfillmentStatus(event){
        const user = secureStorage.getItem("user");
        if (event.target.name === "startpicking"){
            this.setState({fulfillment: null});
            const requestOptions = {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` },
            };
            const response = await fetch(`api/Fulfillments/${this.id}/update/Picking/${user.username}`, requestOptions);
            if (response.status !== 200){
                alert('Failed to update.');
                await this.loadFulfillmentHeader(this.id);
                return;
            }
            await this.loadFulfillmentHeader(this.id);
            return;
        }

        if (event.target.name === "removepicker"){
            this.setState({fulfillment: null});
            const requestOptions = {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` },
            };
            const response = await fetch(`api/Fulfillments/${this.id}/removePicker`, requestOptions);
            if (response.status !== 200){
                alert('Failed to update.');
                await this.loadFulfillmentHeader(this.id);
                return;
            }
            await this.loadFulfillmentHeader(this.id);
        }
    }
    
    async markComplete(){
        this.setState({items: []});
        const user = secureStorage.getItem("user");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        await fetch(`api/Fulfillments/markFulfillmentPicked/${this.state.fulfillment.internalid}/${user.username}`, requestOptions);
        window.location.reload();
    }
    
    compare (a,b){
        const aBin = a.inventorydetail ? a.inventorydetail[0].binnumber : '';
        const bBin = b.inventorydetail ? b.inventorydetail[0].binnumber : '';
        if (aBin < bBin) return -1;
        if (aBin > bBin) return 1;
        return 0;
    }

    render(){
        let fulfillment = this.state.fulfillment;
        let items = this.state.items;
        items = items.sort(this.compare);
        let allItemsPicked = true;
        
        items.forEach(item => {
            if (item.bmsstatus !== "picked")
                allItemsPicked = false;
        });
        
        return (
            <>
                {fulfillment ?
                    <>
                        {this.state.item ? null : 
                        <>
                        <h1><BackButton to={-1}/>  {fulfillment.tranid} ({fulfillment.sonumber})</h1>
                        <h3 style={{fontWeight: 'bold'}}>{fulfillment.name}</h3>
                        <h4>Ship Date: {fulfillment.shipdate}</h4>
                        <h3 style={{fontStyle: 'italic'}}>
                            {fulfillment.bmsstatus ? fulfillment.bmsstatus + ': ' : 'OPEN'} {fulfillment.picker ?  
                            <>
                                <span style={{color: 'red', fontWeight: 'bold'}}>{fulfillment.picker}  </span>
                                {fulfillment.bmsstatus !== 'Picked' &&
                                <Button name={"removepicker"} onClick={this.handleUpdateFulfillmentStatus}>Remove</Button>}
                            </> : null}
                        </h3>
                        {
                            fulfillment && !fulfillment.bmsstatus && items.length > 0 && 
                            <Button name={"startpicking"} onClick={this.handleUpdateFulfillmentStatus} style={largeButtonStyle}>Start Picking</Button>
                        }
                        </>
                        }
                    </>
                : <Loading />
                }
                {fulfillment && items.length > 0 ?
                <>
                    <hr />
                    {(this.state.item && this.state.inventorydetail) ?
                        <PickFulfillmentLine fulfillment={fulfillment} item={this.state.item} inventorydetail={this.state.inventorydetail} cancel={this.closeInventoryDetail} />
                        :
                        <table className='table table-striped' aria-labelledby="tableLabel">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Display Name</th>
                                <th style={{textAlign: 'right'}}>Qty</th>
                                <th>Detail</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.map(item =>
                                <tr key={item.lineid}>
                                    <td style={{fontWeight: 'bold'}}>{item.itemid}</td>
                                    <td>{item.displayname}</td>
                                    <td style={{fontWeight: 'bold', textAlign: 'right'}}>{item.quantity}</td>
                                    {item.bmsstatus === "picked" ? 
                                        <td>
                                            <Button style={{...tableButtonStyle, backgroundColor: colors.grey }} onClick={() => this.openInventoryDetail(item.inventorydetail, item)}>
                                                {item.inventorydetail ?
                                                    'PICKED'
                                                    :
                                                    'No Bin'
                                                }
                                            </Button>
                                        </td> 
                                        :
                                        <td>
                                            {fulfillment.picker && fulfillment.picker === this.user.username ?
                                                <Button style={{...tableButtonStyle, backgroundColor: colors.blue }} onClick={() => this.openInventoryDetail(item.inventorydetail, item)}>
                                                    {item.inventorydetail ?
                                                        item.inventorydetail.map((line) => <div key={line.binnumber}>{line.binnumber}({line.quantity})</div>)
                                                        :
                                                        'No Bin'
                                                    }
                                                </Button>
                                                :
                                                <p>
                                                    {item.inventorydetail ?
                                                        item.inventorydetail.map((line) => <div key={line.binnumber}>{line.binnumber}({line.quantity})</div>)
                                                        :
                                                        'No Bin'
                                                    }</p>
                                            }
                                        </td>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                    }
                    {items.length > 0 && fulfillment.bmsstatus === "Picking" && allItemsPicked && <Button style={largeButtonStyle} onClick={this.markComplete}>Mark Complete</Button>}
                </>
                : <Loading />
                }
            </>
            
        );
    }

    async loadFulfillmentHeader(internalid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/fulfillments/' + this.user.location + '/' + internalid, requestOptions);
        const data = await response.json();
        this.setState({ fulfillment: data[0]});
    }

    async loadItems(internalid) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/fulfillments/' + internalid + '/lines', requestOptions);
        const data = await response.json();
        this.setState({ items: data});
    }
}

export default SingleFulfillment;
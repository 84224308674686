import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";
import {SingleItem} from "../components/inventory/SingleItem";

const SingleItemPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    let {itemId} = useParams();
    return (
        (user && user.viewbininventory) ? <SingleItem itemId={itemId} navigate={navigate} /> : <AccessDenied />
    )
}

export default SingleItemPage;
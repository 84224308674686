import BackButton from "../utility/BackButton";
const ItemHeader = ({item, showInventory, hideUPC}) => {
    return (
        <div style={{marginBottom: 10, paddingBottom: 5, borderBottom: "1px solid lightgrey"}}>
            <h1><BackButton />  {item.itemid}</h1>
            {!hideUPC && <p>UPC: {item.upccode}</p>}
            <h4>{item.displayname}</h4>
            {showInventory && 
                <>
                    <hr />
                    <h3>Overall Inventory</h3>
                    <table className='table table-striped' aria-labelledby="tableLabel">
                        <thead>
                        <tr style={{fontWeight: 'bold'}}>
                            <td>Status</td>
                            <td style={{textAlign: "right"}}>Quantity</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>On Hand</td>
                            <td style={{textAlign: "right"}}>{item.quantityonhand ? item.quantityonhand : 0}</td>
                        </tr>
                        <tr>
                            <td>Available</td>
                            <td style={{textAlign: "right"}}>{item.quantityavailable ? item.quantityavailable : 0}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
            }
        </div>
    );
}

export default ItemHeader;
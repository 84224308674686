import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";
import BinTransfer from "../components/inventory/BinTransfer";

const BinTransferPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    let {id} = useParams();
    return (
        (user && user.enterbincount) ? <BinTransfer id={id} navigate={navigate} /> : <AccessDenied />
    );
}

export default BinTransferPage;
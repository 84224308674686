import React, {Component} from "react";
import {Button, Input} from "reactstrap";
import secureStorage from "react-secure-storage";
import {largeButtonStyle} from "../../styles/BearStyles";
import Loading from "../utility/Loading";

const rightStyle = {textAlign: 'right'}
class BinCountForm extends Component {
    constructor(props) {
        super(props);
        let items = props.items;
        this.bin = props.bin;
        items.forEach((item) => {
            item.count = item.onhand;
        });
        
        this.state = {items: items, adjustments: [], 
            isValidated: false, hasErrors: false, postError: '', 
            posting: false, addingItem: false, missingSkuSearch: ''}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleAddMissingItem = this.handleAddMissingItem.bind(this);
        this.clearAdjustments = this.clearAdjustments.bind(this);
        this.adjustInventory = this.adjustInventory.bind(this);
        this.markCounted = this.markCounted.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }
    
    handleSubmit(event){
        event.preventDefault();
        this.validateItems(this.state.items);
    }
    
    handleChange(event){
        let items = this.state.items;
        let internalid = event.target.name;
        let index = items.findIndex(x => x.iteminternalid === internalid);
        items[index].count = event.target.value;
        this.setState({items: items});
    }

    handleFocus = (event) => event.target.select();
    
    handleSearchChange(event){
        this.setState({missingSkuSearch: event.target.value});
    }
    
    handleAddMissingItem(event){
        event.preventDefault();
        this.getItemBySkuUPC(this.state.missingSkuSearch)
    }
    
    clearAdjustments(){
        this.setState({adjustments: [], isValidated: false, hasErrors: false});
    }
    
    adjustInventory(){
        this.postAdjustment(this.state.adjustments).then((response) => {
            if (response.indexOf('OK') === 0){
                window.location.reload();
            }
            this.setState({posting: false, adjustments: [], isValidated: false, hasErrors: false, postError: 'Failed to Post Adjustment'});
        })
    }
    
    markCounted(){
        this.markBinCounted().then(() => window.location.reload());
    }
    
    render(){
        const items = this.state.items;
        const adjustments = this.state.adjustments;
        return (
            <>
                <h1><Button onClick={this.props.Cancel}>Back</Button>  Count {this.bin && this.bin.binnumber}</h1>
                <h3>{this.state.postError}</h3>
                {
                    !this.state.isValidated &&
                    <>
                        {this.state.addingItem ?
                            <>
                                <form onSubmit={this.handleAddMissingItem}>
                                    <Input type={'text'} disabled={this.state.posting} autoFocus style={{marginBottom: 10}} name={'MissingSkuSearch'} value={this.state.missingSkuSearch} onChange={this.handleSearchChange} />
                                    <input type={"submit"}
                                           value={"Add"}
                                           style={largeButtonStyle}
                                           disabled={this.state.posting}
                                    />
                                    <Button style={largeButtonStyle} onClick={() => this.setState({missingSkuSearch: '', addingItem: false})}>Cancel</Button>
                                </form>
                            </>
                            :
                            <form onSubmit={this.handleSubmit}>
                                <table className='table table-striped' aria-labelledby="tableLabel">
                                    <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th style={rightStyle}>On Hand</th>
                                        <th style={rightStyle}>Available</th>
                                        <th style={rightStyle}>Count</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.map(item =>
                                        <tr key={item.iteminternalid}>
                                            <td>{item.itemid}</td>
                                            <td style={rightStyle}>{item.onhand}</td>
                                            <td style={rightStyle}>{item.available}</td>
                                            <td style={rightStyle}>
                                                <Input type={'number'} autoFocus={items.findIndex(x => x.iteminternalid === item.iteminternalid) === 0} onFocus={this.handleFocus}  style={rightStyle} value={item.count} name={item.iteminternalid} onChange={this.handleChange} />
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <input type={"submit"}
                                       value={"Validate"}
                                       style={largeButtonStyle}
                                       disabled={this.state.posting}
                                />
                                <Button style={largeButtonStyle} onClick={() => this.setState({addingItem: true})}>Add Missing Item</Button>
                            </form>
                        }
                    </>
                    
                }
                
                {this.state.isValidated &&
                    <>
                        <table className='table table-striped' aria-labelledby="tableLabel">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th style={rightStyle}>On Hand</th>
                                <th style={rightStyle}>Available</th>
                                <th style={rightStyle}>Count</th>
                                <th style={rightStyle}>Adjustment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {adjustments.map(item =>
                                <tr key={item.iteminternalid}>
                                    <td>{item.itemid}</td>
                                    <td style={rightStyle}>{item.onhand}</td>
                                    <td style={rightStyle}>{item.available}</td>
                                    <td style={rightStyle}>{item.count}</td>
                                    <td style={rightStyle}>
                                        <div>{item.difference}</div>
                                        <div style={{color: '#dc1111'}}>{item.error}</div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {
                            adjustments.length > 0 ?
                                <>
                                    {this.state.hasErrors ? 
                                        <Button style={largeButtonStyle} onClick={this.clearAdjustments}>Fix Errors</Button>
                                        :
                                        <Button style={largeButtonStyle} disabled={this.state.posting} onClick={this.adjustInventory}>Adjust Inventory</Button>
                                    }
                                </>
                                :
                                <>
                                    <h2 style={{textAlign: 'center', marginBottom: 20}}>No adjustments</h2>
                                    <Button style={largeButtonStyle} disabled={this.state.posting} onClick={this.markCounted}>Mark Counted</Button>
                                </>
                        }
                    </>
                }
                {this.state.posting && <Loading />}
            </>
        );
    }
    
    validateItems(items){
        let adjustments = [];
        let hasErrors = false;
        items.forEach((item) => {
            let error = '';
            const count = parseInt(item.count);
            const onhand = parseInt(item.onhand);
            const available = parseInt(item.available);
            const difference = count - onhand;
            
            //Errors
            if (count < 0 || count < onhand - available){
                error = 'Count must be greater than picked inventory';
                hasErrors = true;
            }
            
            if (difference !== 0){
                adjustments.push({
                    ...item,
                    difference,
                    error
                });
            }
        });
        this.setState({adjustments, isValidated: true, hasErrors});
    }
    async postAdjustment(items){
        this.setState({posting: true})
        const user = secureStorage.getItem("user");
        let itemsForAdjustment = [];
        items.forEach((item) => {
            const add = {
                iteminternalid: item.iteminternalid,
                bininternalid: item.bininternalid,
                difference: item.difference
            }
            itemsForAdjustment.push(add);
        });
        
        let data = {
            type: 'adjustment',
            memo: 'BearWMS Bin Count - ' + user.username + ' ' + new Date().toLocaleDateString('en-US'),
            date: new Date().toLocaleDateString('en-US'),
            location: user.location,
            lastcountedby: user.username,
            items: itemsForAdjustment
        }
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify(data)
        };
        const response = await fetch('api/Items/adjust', requestOptions);
        return await response.text();
    }
    
    async getItemBySkuUPC(skuSearch) {
        if (skuSearch.length <= 2) return;
        this.setState({posting: true})
        const user = secureStorage.getItem("user");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        const response = await fetch('api/items/lookup/' + user.location + '/' + skuSearch, requestOptions)
        const data = await response.json();
        const error = data.length > 0 ? '' : 'No results';
        if (error){
            this.setState({postError: 'No matching item found.', posting: false});
            return;
        }
        
        let items = this.state.items;
        let itemToAdd = {
            iteminternalid: data[0].internalid.toString(),
            bininternalid: this.bin.internalid,
            binnumber: this.bin.binnumber,
            displayname: data[0].displayname,
            itemid: data[0].itemid,
            onhand: 0,
            available: 0,
            count: 0
        }
        items.push(itemToAdd);
        
        this.setState({ items: items, missingSkuSearch: '', posting: false, addingItem: false });
    }
    
    async markBinCounted(){
        this.setState({posting: true});
        const user = secureStorage.getItem("user");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        const response = await fetch('api/items/bins/markCounted/' + this.bin.internalid + '/' + user.username, requestOptions);
        this.setState({posting: false});
        return await response.text();
    }
}

export default BinCountForm;
import React from "react";
import LoginForm from "../components/LoginForm";

const LoginPage = () => {
    return (
        <>
            <LoginForm />
        </>
        )
}

export default LoginPage;
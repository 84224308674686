import React, { Component } from "react";
import secureStorage from "react-secure-storage";
import {Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import Loading from "../utility/Loading";
import ItemHeader from "./ItemHeader";
import ItemBinList from "./ItemBinList";
import {largeButtonStyle} from "../../styles/BearStyles";
import ScannerGlobalSearch from "../utility/ScannerGlobalSearch";

export class SingleItem extends Component {
    constructor(props) {
        super(props);
        this.user = secureStorage.getItem("user");
        this.navigate = props.navigate;
        this.state = { itemId: props.itemId, bins: [], loading: true, item: null };
        this.handleBack = this.handleBack.bind(this);
        this.startBinTransfer = this.startBinTransfer.bind(this);
    }
    
    componentDidMount() {
        this.loadItem(this.state.itemId);
        this.loadBins(this.state.itemId);
    }

    handleBack(){
        this.navigate('/inventory')
    }
    
    startBinTransfer(){
        this.navigate('/bintransfer/' + this.state.itemId);
    }
    
    render() {
        return (
            <div>
                {this.state.item ? <ItemHeader item={this.state.item} showInventory={true} /> : <Loading />}
                {this.state.loading ? <Loading /> : <ItemBinList bins={this.state.bins} /> }
                {!this.state.loading && this.state.bins.length > 0 && 
                    <Button style={largeButtonStyle} onClick={this.startBinTransfer}>Bin Transfer</Button>}
            </div>
        );
    }

    async loadItem(itemId) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/items/' + this.user.location + '/' + itemId + '/info', requestOptions)
        const data = await response.json();
        this.setState({ item: data });
    }
    async loadBins(itemId) {
        if (itemId.length < 2){
            this.setState({bins: [], loading: false});
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };
        const response = await fetch('api/items/' + this.user.location + '/' + itemId + '/bins', requestOptions)
        const data = await response.json();
        this.setState({ bins: data, loading: false});
    }
}
import React, {Component} from "react";
import BackButton from "../utility/BackButton";
import secureStorage from "react-secure-storage";
import {Input} from "reactstrap";
import {largeButtonStyle} from "../../styles/BearStyles";
import Loading from "../utility/Loading";

class EditBin extends Component {
    constructor(props) {
        super(props);
        this.id = props.id;
        this.navigate = props.navigate;
        this.user = secureStorage.getItem("user");
        this.state = {bin: null, binnumber: '', memo: '', errormessage: '', loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.loadBinInfo(this.id);
    }
    
    handleSubmit(event){
        event.preventDefault();
        if (!this.state.binnumber){
            this.setState({errorMessage: 'Bin must have a bin number!'});
            return;
        }
        
        this.saveBinInfo();
    }
    
    handleChange(event){
        const name = event.target.name;
        if (name === 'binnumber')
            this.setState({binnumber: event.target.value})
        if (name === 'memo')
            this.setState({memo: event.target.value})
    }
    
    render() {
        return (
            this.state.loading ? <Loading /> : 
            <>
                <h1><BackButton />   Edit Bin Info</h1>
                <form onSubmit={this.handleSubmit}>
                    <Input type={"text"} name={'binnumber'} value={this.state.binnumber} onChange={this.handleChange}/>
                    <p>Bin Number</p>
                    <Input autoFocus type={"text"} name={'memo'} value={this.state.memo} onChange={this.handleChange}/>
                    <p>Memo</p>
                    <input disabled={this.state.loading} type={"submit"} value={"Save"} style={largeButtonStyle}/>
                    {this.state.loading && <Loading />}
                    <p style={{textAlign: 'center', marginTop: 5}}>{this.state.errorMessage}</p>
                </form>
            </>
        );
    }


    async loadBinInfo(binId) {
        if (!binId)
            return;
        
        this.setState({loading: true});
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.user.token}` },
        };

        const response = await fetch('api/items/bins/lookupById/' + binId, requestOptions)
        if (response.status !== 200){
            this.setState({ loading: false, errorMessage: 'No results' });
            return;
        }

        const data = await response.json();
        this.setState({bin: data, binnumber: data.binnumber, memo: data.memo, loading: false});
    }
    
    async saveBinInfo(){
        this.setState({loading: true});
        const data = {
            type: 'updatebininfo',
            internalid: this.id,
            binnumber: this.state.binnumber,
            memo: this.state.memo
        }
        console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.user.token}` },
            body: JSON.stringify(data)
        };

        const response = await fetch('api/Items/updatebininfo', requestOptions);
        if (response.status !== 200){
            this.setState({errorMessage: 'Failed to update.', loading: false});
            return;
        }
        this.navigate('/bins/' + this.id);
    }
}

export default EditBin;
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";
import EditBin from "../components/inventory/EditBin";

const EditBinPage = () => {
    const user = secureStorage.getItem("user");
    let {id} = useParams();
    const navigate = useNavigate();

    return (
        (user && user.updatebininfo) ? <EditBin id={id} navigate={navigate} /> : <AccessDenied />
    )
}

export default EditBinPage;
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import SingleFulfillment from "../components/fulfillments/SingleFulfillment";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";

const SingleFulfillmentPage = () => {
    const user = secureStorage.getItem("user");
    let {id} = useParams();
    const navigate = useNavigate();

    return (
        (user && user.vieworders) ? <SingleFulfillment id={id} navigate={navigate} /> : <AccessDenied />
    )
}

export default SingleFulfillmentPage;
import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import PublicRoutes from "./PublicRoutes";
import {Layout} from './components/Layout';
import './custom.css';
import secureStorage from 'react-secure-storage';

export default class App extends Component {
  static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { user: null };
        this.logout = this.logout.bind(this);
    }
    
    componentDidMount() {
        const user = secureStorage.getItem("user");
        if (user){
            this.getCurrentUserFromAPI(user.token).then(validResponse => {
               if (validResponse)
                   this.setState({user: user});
               else {
                   this.setState({user: null});
                   secureStorage.removeItem("user");
               }
            });
        } else {
            this.setState({user: null});
        }
    }
    
    logout(){
        secureStorage.removeItem("user");
        this.setState({user: null});
    }

    render() {
        const routes = this.state.user ? AppRoutes : PublicRoutes
        
        return (
          <Layout logout={this.logout}>
            <Routes>
              {routes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Layout>
        );
    }
    
    async getCurrentUserFromAPI(token){
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const response = await fetch('api/User/current', requestOptions);
        return response.status === 200;
    }
}

import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import icon from '../images/BearWMS_Icon_128.png';
import secureStorage from "react-secure-storage";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.clickLogout = this.clickLogout.bind(this);
    this.state = {
      collapsed: true,
      user: null
    };
  }
  
  componentDidMount() {
    const user = secureStorage.getItem("user")
    this.setState({collapsed: true, user});
  }

  toggleNavbar () {
    this.setState({collapsed: !this.state.collapsed});
  }
  
  clickLogout(){
    this.setState({collapsed: true, user: null});
    this.props.logout();
  }

  render () {
    let user = this.state.user;
    return (
        <header>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container>
              <img src={icon} alt={'bearwms'} style={{width: 36, height: 36}} />
              <NavbarBrand className={"app-title"} tag={Link} to="/">BEAR WMS</NavbarBrand>
              {this.state.user &&
                <>
                  <NavbarToggler onClick={this.toggleNavbar} className="mr-2 collapse-button" />
                  <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                      <ul className="navbar-nav flex-grow">
                        <NavItem onClick={this.toggleNavbar}>
                          <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                        </NavItem>
                        {user && user.viewbininventory &&
                          <NavItem onClick={this.toggleNavbar}>
                            <NavLink tag={Link} className="text-dark" to="/bins">Count Bin</NavLink>
                          </NavItem>
                        }
                        {user && user.viewbininventory &&
                        <NavItem onClick={this.toggleNavbar}>
                          <NavLink tag={Link} className="text-dark" to="/inventory">Item Lookup</NavLink>
                        </NavItem>
                        }
                        {user && user.vieworders &&
                        <NavItem onClick={this.toggleNavbar}>
                          <NavLink tag={Link} className="text-dark" to="/pickOrders">Pick Orders</NavLink>
                        </NavItem>
                        }
                        <NavItem>
                          <NavLink onClick={() => this.clickLogout()}>Logout</NavLink>
                        </NavItem>
                      </ul>
                  </Collapse>
                </>
              }
            </Container>
          </Navbar>
        </header>
    );
  }
}

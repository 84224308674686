import React from "react";
import {FulfillmentsList} from "../components/fulfillments/FulfillmentsList";
import {NavLink, useNavigate} from "react-router-dom";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";

const FulfillmentsPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    
    return (
        (user && user.vieworders) ? <FulfillmentsList navigate={navigate} /> : <AccessDenied />
    );
}

export default FulfillmentsPage;